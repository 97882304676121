/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx,css } from '@emotion/react'
import { useState, useMemo, FC } from 'react';
import { MCQnavigator, AnswerCardManager, FeedBackManager, ImageCard, } from './componant';
import { Popup } from '../../components';

import { useParams } from 'react-router-dom';
import {useFetchQuestionFromSupabase} from "../../API/questionAPI"
import ErrorRaport from './componant/ErrorRaport';

//todoliste:
//faire un rapport pour les erreur envoyer en cas de problème
//


export default function MCQComponants() {
      let { id } = useParams();
      const datas = useFetchQuestionFromSupabase() 
      
      const questions = useMemo(() => {
        return datas.filter((data)=>(data.categories.split(",").includes(id)))
      }, [datas]);

      const [questionIndex, setQuestionIndex] = useState(0);
      const [answeredState, setAnsweredState] = useState("notAnswered");
      const [showPopup, setShowPopup] = useState(false)

      // Met la question de à index 0
      const data = questions[questionIndex];

      return (
        <div className='MCQ-content'>
          {data ? ( // Vérifiez si "data" est défini
            <div  className ="MCQ-categorie">

          <div>
            <Popup trigger = {showPopup}
                    >
              <ErrorRaport 
              data = {data}
              setTrigger = {setShowPopup}
              id = {data.id}
              />
            </Popup>
            </div>
              <MCQnavigator
                props={{
                  setQuestionIndex,
                  questionIndex,
                  dataLength: questions.length,
                }}
              />
                <button onClick={()=>setShowPopup(true)}>Il y a une erreur</button>
              {data.url? <ImageCard url = {data.url}/>:""}
              <QuestionContent questionsData={data.content} />
              {/* <ChallengeCard number={2} /> */}
              <AnswerCardManager
                props={{
                  isRightAnswered: answeredState,
                  setAnsweredState,
                  answer_A: data.answer_A,
                  answer_B: data.answer_B,
                  answer_C: data.answer_C,
                  answer_D: data.answer_D,
                  answer_E: data.answer_E,
                  answer: data.answer,
                }}
              />
              <FeedBackManager
                props={{
                  answeredState: answeredState,
                  hint: data.hint,
                  answerExplain: data.answersExplain,
                  sources: data.sources,
                }}
              />
            </div>
          ) : (
            // Affichez une page d'erreur si "data" est undefined
            //retravailler la logique selon si ça charge our lance une erreur
            <div className='mcq-loading'>
              <h1>La page recharge les questions</h1>
            </div>
          )}
        </div>
      );
    }

//display the content of the question
function QuestionContent({questionsData: content}: {questionsData:any}){
      return (
            <div>
                  <div css={css({
                       maxWidth:"60vw",
                  })}>
                        {content}      
                  </div>
                  <div className="MCQresponse">
                  </div>
            </div>
      )
}

