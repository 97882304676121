/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx,css} from "@emotion/react"
import React from "react"
import { AiOutlineHeart, AiFillHeart, AiOutlineArrowRight,AiOutlineArrowLeft} from "react-icons/ai"; 
//
// CONTAIN ALL BTN COMPONANT FOR THE APP
//
export function ButtonArrow({props}: {props :any }) {
     const {icon, setQuestionIndex} = props
    const button_arrow_true = 
          css({
                border: '1px solid #eee',
                borderRadius: '50%',
                padding: '1rem',
                size: "",
                })
    const button_arrow_false = 
          css({
                border: '1px solid #eee',
                borderRadius: '50%',
                padding: '1rem',
                size: ""
                })
          
return (
    <button onClick = {()=>{setQuestionIndex()}} css = {button_arrow_true}> {icon} </button>
)
}

/** @jsxRuntime classic */
/** @jsx jsx */
export function IsFav({setFav, isFav}: {setFav:Function, isFav : boolean})
{     
      var size: number = 30 
      return (
            <div onClick = {()=> setFav(!isFav)}
            css= {css`
            padding-right:4px;
            `}>
                  {isFav? 
                        <AiFillHeart size={size}/>:
                        <AiOutlineHeart size={size}/>
                  }
            </div>
           
      )
};
export function GenericBtn(props:any){
      return <div> 
                  <div 
                        onClick = {props.onDelete} 
                        style ={{
                              backgroundColor : props.bgColor, 
                              cursor : "pointer", 
                              fontSize : "20px" as const,
                              fontWeight: "bold", 
                              padding: "4px", 
                              borderRadius : "5px", 
                              color: "white"
                        }}> 
                              {props.children}
                  </div>
            </div> 
}