
/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';
import {jsx,css} from "@emotion/react"
import mainTheme from '../shared/theme';


export default function Footer() {

  const style = css`
    font-size:20px;
    background-color: ${mainTheme.color.bgColor};
    text-align: center;
    margin-top: auto;
    padding: 8px;
  `
  const container = css`
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items:center;
    padding: 30px;
    font-size: 0.8em;
  `
  return (
      <div css={style}>
       <div css={container}>
        <div css ={css`
        text-align:start;
        `} >
            Développé avec ♡
            <br />
        </div>
        <div>
            2023-{new Date().getFullYear()}
        </div>
        </div>
      </div>
  )
}
