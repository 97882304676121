import React, {useState} from 'react'
import "./componant.css"
import { supabaseClient } from '../../../config/supabase-client'
import { getURL } from '../../../API/questionAPI'
// Logique pour aggrendire l'image quand on clique dessus
export default function ImageCard(props:any) {
  const [zoom, setZoom] = useState(false)
  const toggleZoom = ()=>{
    console.log(zoom)
    setZoom(zoom=>!zoom)
  }
  
    const {data} = getURL(props.url)
  return (
   <div className = "container-MCQ-image" onClick={toggleZoom}> 
         {/* Pour préserver la taille de la */}
         <img className = "MCQ-image"src = {data.publicUrl}/>
        {zoom?
            <div className='container-MCQ-zoom' onClick={toggleZoom}>
              <img className = "MCQ-image-zoom"src={data.publicUrl} onClick={toggleZoom}/>
              <div className="MCQ-image-btn" onClick={toggleZoom} >Quitter</div>
            </div>
              :
           ""
        }
   </div>
  )
}
 