import React from 'react'
import { supabaseClient } from '../config/supabase-client'

const TABLE = "logError"

export async function insertError(data:any){
    try{
        console.log("Début d'ajout des questions")
        console.log("Donnée envoyée", )
        console.log("Donnée",data.title)
        console.log("Donnée", data.comment)
        const {error} = await supabaseClient
        .from(TABLE)
        .insert([
            data
        ])
        
        if (error){
            console.error("Une erreur lors de l'insértion des données: ", error)
            return {status : false, message: "Une erreur lors de l'insértion des données:", error}
        } else {
            console.log("Les données ont été insérées")
            return {status : true, message: "Les données ont été insérées"}
        }
    } catch(err){
        console.log("Il y a une erreur server: ",err )
        return {status : true, message: "Il y a une erreur server: ",err}
    }
  
}
                                
                                
