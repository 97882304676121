
import React, {createContext, useContext, useState, useMemo} from "react"
import { ToastContainer } from "../components"

type ToastContextValue = {
    open: (message:string, status:string)=>void,
    close: (id:number)=>void,
  }

  type ToasProviderProreties = {
    children: React.ReactElement
  }

  type ToastType = {
    message:string,
    id:number,
    status:string,
  }
  
const ToastContext = createContext<ToastContextValue|null>(null)
export const useToast = () => useContext(ToastContext)

export function ToastProvider ({children}: ToasProviderProreties){
  const [toasts, setToast] = useState<ToastType[]>([])
  
  function openToast (message:string, status : string){
    console.log("New toast")
    const newToast = {
      id:Date.now(),
      message:message,
      status: status
    };
    setToast ((previousToasts)=>[...previousToasts, newToast]) 
    setTimeout(() => {
        closeToast(newToast.id);
      }, 4350);
  }

  function closeToast (id:number){
    setToast ((previousToasts)=>previousToasts.filter(toast =>toast.id !=id))
  }

  const contextValue = useMemo(()=>({
    open:openToast,
    close:closeToast
  }),[])

  return (
    <>
    <ToastContext.Provider value={contextValue}>
      {children}
      <ToastContainer toasts={toasts} onClose={closeToast} />
    </ToastContext.Provider>
    </>
  )
}

