import React from 'react'
import "./components.css"

export default function Popup(props:any) {
  return (props.trigger)?(
    <div className = "fullscreen-container"> 
        <div className = "popup">
            <div className ="popup-inside">
                  
                {props.children}
            </div>
        </div>
    </div>
  ):<div></div>
}
