import React, {useState} from "react"
import "./components.css"
import { getURL } from "../API/questionAPI"
export default function UploadImage(props:any) {
    const {data} = getURL(props.url)
    const [prevUrl, setPrevURl] = useState(`${data.publicUrl}`)
    console.log(prevUrl)
    

    const handleOnChangeUpload = (event:any)=>{
      const file= event.target.files[0]
      setPrevURl(URL.createObjectURL(file))
      console.log(prevUrl)
      props.setMedia(event.target.files[0])
      props.setURL(URL.createObjectURL(file)) //set URL
      props.formData.url = `MCQ/${file.name}` //set new name
    }
    return (
      // Limiter la taille en hauteur et longeur
      <div className = "upload-main"> 

            <label htmlFor="upload" className="btn-upload">Selectionner une image</label>
            <input  style={{visibility: "hidden"}} type="file" id="upload" onChange = {handleOnChangeUpload} name ="image"/>
            {props.url?<img className = "upload-previz" id="upload" src={prevUrl} />:"Pas d'image actuellement" }

      </div>
    )
}
