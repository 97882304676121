import React from "react"

  type ToastProperties= {
    message:string,
    close : ()=>void
    id : number
    status : string
  }

  type ToastType = {
    message:string,
    id:number,
    status:string,
  }

export function ToastContainer({ toasts, onClose }: { toasts: ToastType[], onClose: (id: number) => void }) {
    return (
      <div className="toasters-container">
        {toasts.map(toast => (
          <Toast
            key={toast.id}
            status={toast.status}
            id={toast.id}
            message={toast.message}
            close={() => onClose(toast.id)}
          />
        ))}
      </div>
    );
  }

function Toast({id,message, status: type, close}:ToastProperties){
    const toastColors: Record<string,string> = {
        "error": "red",
        "warning": "yellow",
        "success": "green"
      };

    const backgroundColor = toastColors[type] || "transparent";
      
    return (
      <div className="toaster-content" key= {id.toString()} style = {{backgroundColor:`${backgroundColor}`}}>
        <p className="text">{message}</p>
        <button onClick={close} className='close-btn'>X</button>
      </div>
    )
  }