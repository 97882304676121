import { UUID } from "crypto";
import { supabaseClient } from "../config/supabase-client";
import data2 from "../shared/data/data2.json" 
import { User } from '@supabase/supabase-js';

//Need to seperate 
const ERROR_MESSAGES = {
    generic: "Il y a eu une erreur côté serveur",
    signIn: "Il y a eu une erreur",
    success: "Succés!"
  };

type loginType = {
    setUser: (user:User|null)=>void
    setLoading: (bool:boolean)=>void 
    setLocalSession: (session:any)=>void | null
    password : string 
    email:string 
}

export async function setData(){
    const {data:{user}, error} = await supabaseClient.auth.getUser()
    try{
        if (user){
            console.log("User is set:", user)
            return { message: `${ERROR_MESSAGES.success}`, errorLogin: false, user:user}
        } else{
            return { message: `${ERROR_MESSAGES.generic} ${error}`, errorLogin: true, user:undefined };
        }
    } catch(err){
          console.log("Il y a une erreur côté serveur: ", err)
          return { message: `${ERROR_MESSAGES.generic} ${err}`, errorLogin: true, user:undefined };
    }}

export function signOut({setLocalSession}:loginType){
    supabaseClient.auth.signOut()
    // IT WILL DELETE ALL THE SESSION; WATCHOUT!
    setLocalSession(null) 
  }

type Login = {
    email:string;
    password:string;}

//ne fonctionne pas encore
export async function sign_in({email, password}:loginType){
    try {
      const { data, error } = await supabaseClient.auth.signInWithPassword({ email: email,
      password: password,
      });
      const user = data.user
      console.log("Les profiles", user)
      console.log("Connecté avec succès:", data);
    //   setUser(user);
      if (error) {
        console.error("Erreur de connexion:", error);
        return { message: `${ERROR_MESSAGES.signIn} ${error}`, errorLogin: true, user:user};
      }
      return { message: ERROR_MESSAGES.success, errorLogin: false, user:user};
    } catch (err) {
      console.error("Erreur côté serveur:", err);
      return { message: `${ERROR_MESSAGES.generic} ${err}`, errorLogin: true, user: undefined};
    }
  }

  export async function checkAdmin(user:User ){
    let { data:profiles, error } = await supabaseClient
    .from('profiles')
    .select('is_admin')
    .eq("id", user.id)

  
    try {
        const isAdmin :boolean = profiles![0].is_admin
        if (error){
          console.log("Erreur dans la récupration des donnée", error)
          return false
        }
        return isAdmin
      }
      catch(err){
      console.error("Erreur dans la récupération des données: ", err)
      return false
    }
  }