/** @jsxRuntime classic */
/** @jsx jsx */
import AuthProvider from './hook/authProvider';
import React from 'react'
import { jsx, css} from '@emotion/react'
import { ToastProvider } from './hook/toasterProvider';
import './index.css';
import reportWebVitals from './reportWebVitals';
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from './App';
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <BrowserRouter>
        <ToastProvider>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ToastProvider>
    </BrowserRouter>
  </React.StrictMode>
);

//SOMMAIRE DES PAGES

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Root/>, //La base de pages
//    children:  
//    [{
//     path: "QCM/:id",
//     element: < MCQComponants/>,
   
//     },
//     {
//       path: "QCM/",
//       element: <TopicNavigationPage/>,
//     },
//     {
//       path:"homepage/",
//       element: <Homepage/>
//     },
//     {
//       path:"ressources/",
//       element: <RessourcePage/>
//     },
//     {
//       path:"test/",
//       element: <TestPage/>
//     },
//     {
//       path:"debug/",
//       element: <DebugPage/>
//     }
//   ],
//   },
// ]);


// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//         <AuthProvider>
//     <div css= {css`
//           display:flex;
//           flex-direction: column;
//           min-height: 100vh;
//             `} > 
//    <RouterProvider router={router} />
//    </div>
//    </AuthProvider>
//   </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
