  import React, {useState, useContext} from 'react'
  import { useAuth } from '../../hook/authProvider'
  import "./signin.css"
  import { redirect, useNavigate } from "react-router-dom";
  import { useToast } from '../../hook/toasterProvider';
  //todo:
  //- CSS and styling
  //- Loading page or animation
  //- Returning to homepage 
  
  export default function SigninPage() {
      const toast = useToast()
      const [passwordUser, setPasswordUser] = useState("")
      const [passwordMagical, setPasswordMagical] = useState("")
      const [isLoading, setLoading] = useState(false)
      const { signIn, user, signInMagical } = useAuth()
      const navigate = useNavigate()

      async function handleSubmitGuestAccount (){
        const dataLogin = {
          email: "guest@sauvmesfed.ch",
          password : passwordUser
        }
        //A modifier pour faire avec réponse server
        setLoading(true) 
          const {message, errorLogin} = await signIn(dataLogin)
          if (errorLogin){
            toast!.open(`${message}`, "error")
            setLoading(false)
          } else {
            toast!.open(`${message}`, "success")
            setLoading(false)
            navigate("/")
          }

          //FAIRE ATTENTION RENVOYER ERRUR SI MARCHE PAS! 
      }

      // function handleSubmitMagical(){
      //   console.log(localStorage.getItem('sessionData'))
      //   setLoading(true) 
      //   setTimeout(function() {
      //     setLoading(false)
      //     signInMagical(passwordMagical)
      //     //FAIRE ATTENTION RENVOYER ERRUR SI MARCHE PAS! 
      //     navigate("/")

      // }, 3000);}


      return (
      <div>
          <div>
            <div className='signIn-container'> 
              <div className='login-container'>
                <div className='label'>
                  <h1 style = {{textAlign:"center"}}>Insérez les mot de passe pour accéder au site</h1>
                  {/* <label htmlFor="username">Email:</label>
                  <input 
                    type="text" 
                    id="username" 
                    name="username" 
                    placeholder="Votre email" 
                    onChange={(e)=>setUser(e.target.value)} 
                    value = {userName}
                    /> */}
                  <label htmlFor="password">Mot de passe:</label>
                  <input type="password" id="password" name="password" placeholder="Insérez votre mot de passe" onChange={(e)=>setPasswordUser(e.target.value)} value = {passwordUser}/>
                    <button className = "btn-sending" onClick ={handleSubmitGuestAccount}>Se connecter</button>

                </div>
              </div>
            </div>
        </div>
      </div>
    )
  }
