import "./debugPage.css"
import {useState, useEffect} from "react"

import { Popup, GenericBtn } from '../../components'
import { EditQuestion } from "./components/EditQuestion"
import { AddQuestion } from "./components/AddQuestion"
import {useFetchQuestionFromSupabase, deleteQuestionDatabase} from "../../API/questionAPI"
import { useToast } from "../../hook/toasterProvider"
// to do -> display all modifications after adding new element in an array either calling inside API each render

export default function DebugPage() {
    const toast = useToast()
    const questions= useFetchQuestionFromSupabase()
    const [displayQuestions, setDisplayQuestion] = useState(questions)
    const [windowState, setWindow] = useState("")
    const [buttonPopup, setButtonPopup] = useState(false)
    const [questionState, setQuestion] = useState()
    useEffect(()=>{
        setDisplayQuestion(questions)
    },[questions]
    )

    interface ComponentEnum {
        [key: string]: JSX.Element;
      }
    const Enumobj: ComponentEnum = {
        edit: <EditQuestion
                    question = {questionState} 
                    setTrigger = {setButtonPopup}
                    onDelete= {handleDeleteQuestion}
                />,
        add : <AddQuestion 
                    setQuestion = {setDisplayQuestion}
                    setTrigger = {setButtonPopup}
                    question = {questions}/>
        }
    const RenderComponent = () => {
        return Enumobj[windowState] || null; // Retourne le composant ou null si la clé n'est pas trouvée
    };
    
    function handleEditQuestion(id:number):void{
        // affiche popup et met la bonne question à éditer dans le formulaire avec un state
        setWindow("edit")
        setQuestion(questions.find(question => question.id === id));
        setButtonPopup(true)
    }

    async function handleDeleteQuestion(id:number){
        // Secondaire: Si possible faire une fenêtre personalisée pour la confirmation
          const confirmDelete = window.confirm(`Êtes-vous sûr de vouloir supprimer cette question ${id} ?`);
        if(confirmDelete){
            const updatedDisplayQuestions = questions.filter(question => question.id !== id);
            const {success, message, error} = await deleteQuestionDatabase(id)
            if (success){
                setDisplayQuestion(updatedDisplayQuestions);
                setQuestion(questions.find(question => question.id === id));
                setButtonPopup(false)
                toast!.open(message, "success")
            } else{
                toast!.open(message, "error")
            }
        }
        else {}
    }

    function handleAddQuestion(){
        const updatedDisplayQuestions = displayQuestions;
        setDisplayQuestion(updatedDisplayQuestions);
        setWindow("add")
        setButtonPopup(true)
    }

    return  <div className = "main"> 
        <div >
        {/* Popup qui va afficher les paramètres des questions et possibilité de supressions */}
      
            <Popup trigger = {buttonPopup} setTrigger = {setButtonPopup}>
                    <RenderComponent/>
            </Popup>
        </div>
      
        <h2>Editeurs de questions</h2>
        <GenericBtn bgColor = "#c6e2e9" >
            <div onClick= {handleAddQuestion}  > Ajouter une question </div>
        </GenericBtn>
        <table className = "content-table" >
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Content</th>
                    <th>Error</th>
                    <th>Crée le</th>
                    <th>Spécialité</th>
                    <th>Hint?</th>
                    <th>Explication?</th>
                    <th>Image?</th>
                </tr>
            </thead>
                <tbody>
                    {/* Utilise displayQuesiton pour réactualiser la page*/}
                    {displayQuestions.map((question) => (
                        <tr 
                            className = "row-content" 
                            onClick = {()=>handleEditQuestion(question.id)} 
                            key={question.id} >
                                <td>{question.id}</td>
                                <td>{question.content.slice(0,100)}...</td>
                                <td>{/* Error content */}</td>
                                <td>{new Date(question.create_at).  toLocaleDateString('fr-FR')}</td>
                                <td>{question.categories.split(",").join(" - ")}</td>
                                {/* Bricolage */}
                           
                               {question.explain?<td style={{backgroundColor:"Green"}}>Oui</td>:<td style={{backgroundColor:"red"}}>Non</td>}
                               {question.hint?<td style={{backgroundColor:"Green"}}>Oui</td>:<td style={{backgroundColor:"red"}}>Non</td>}
                               {question.url?<td style={{backgroundColor:"Green"}}>Oui</td>:<td style={{backgroundColor:"red"}}>Non</td>}
                        </tr>
                    
                    ))}
                </tbody>
            </table>
        </div>
    
}
