import React, {useState, useEffect} from "react"
import { addingQuestionDatabase,uploadFile } from "../../../API/questionAPI";
import { useToast } from "../../../hook/toasterProvider";

import { UploadImage } from "../../../components";
export function AddQuestion(props: any) {
  const toast = useToast()
  var data = props.question 
    // Objet contenant qui va être mapper pour le contenu trouver un solutation pour mapper les éléments en changeant le nom. Utiliser un mappeur
  const [formData, setFormData] = useState({
      content:  "",
      answer_A: "",
      answer_B:  "",
      answer_C:  "",
      answer_D:  "",
      answer_E: "",
      answer:  "",
      hint: "",
      explain: "",
      questionType: "",
      categories: "",
      url:""
    });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [media, setMedia] = useState<any>()
  const [url, setURL] = useState("")
  
  useEffect(() => {
      // Ajuster dynamiquement la hauteur du textarea en fonction de son contenu lors du chargement initial
      adjustTextAreaHeight();
  }, []);

  const handleChange = (event: any) => {
      const { name, value } = event.target;
      setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
      adjustTextAreaHeight();
  };

  const handleSubmit = async (event: any) => {
      event.preventDefault();
    //   Formate l'envoie d'un array
       
      const { success, message } = await addingQuestionDatabase(formData);
      if (success) {
          props.setQuestion([...props.question, formData])
          props.setTrigger(false)
          uploadFile(media)
          toast!.open("La question a été ajoutée avec succés!", "success")

      } else {
        toast!.open(`Échec de l'ajout de la question: ${message}`, "error")
      }
      setModalIsOpen(true);
  };

  const adjustTextAreaHeight = () => {
      const textAreas = document.querySelectorAll('textarea');
      textAreas.forEach((textarea) => {
          textarea.style.height = 'auto';
          textarea.style.height = `${textarea.scrollHeight}px`;
      });
  };
 
  const closeModal = () => {
      setModalIsOpen(false);
  };
// Trouver un moyen de refresh 
  return (
      <div>
           <form onSubmit={handleSubmit} className="form-inside">
              {/* itierate through formData for all textField */}
                <div className= "form-head">
                    <button onClick = {()=>props.setTrigger(false)}className="popup-btn">X</button> 
                    <p className='form-title'> Ajouter une question </p> 
                    <p > More... MOOOOOORE</p>  
                </div>
              {/* Affiche la réponse serveur */}
              {modalIsOpen && (
                              <div className="modal-overlay">
                                  <div className="modal">
                                      <h2>{modalMessage}</h2>
                                      <button onClick={closeModal}>okay</button>
                             
                                  </div>
                              </div>
                          )}
                      <div className='form-header'>
                      </div>
                      {Object.entries(formData).map(([key, value]) => (
                        key !== 'url'? //bricolage, revoir comment montrer les donnée
                        <div key={key} 
                              className = "container-input">
                              <label className ="label-content" htmlFor={key}>
                                  {key}
                              </label>
                              <textarea
                                  id={key}
                                  className = {`input-text`}
                                  name={key}
                                  value={value}
                                  onChange={handleChange}
                                  required = {key === 'content' || key === 'answer' || key === 'categories'||
                                  key === 'answer_A' ?true:false}
                              />
                            </div>
                              :
                              ""
                        ))}
                        {/* BUTTON UPLOAD*/}
                        <UploadImage  url = {url} setMedia ={setMedia} setURL = {setURL} formData = {formData}/>
                 
              
              <div className = "popup-bottom">
                  <button type="submit">Ajouter la question</button> 
              </div>
            
          </form>
          {/* display success or error message */}
          
      </div>
  );
}

