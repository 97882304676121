import {useState, useEffect} from "react"
import { updateQuestionFromSupabase,uploadFile } from "../../../API/questionAPI"
import {UploadImage} from "../../../components"
import { GenericBtn } from "../../../components"
import { useToast } from "../../../hook/toasterProvider"

export function EditQuestion(props: any) {
    var data = props.question 
    const toast = useToast()
    const [formData, setFormData] = useState({
        content: data.content || "",
        answer_A: data.answer_A || "",
        answer_B: data.answer_B || "",
        answer_C: data.answer_C || "",
        answer_D: data.answer_D || "",
        answer_E: data.answer_E || "",
        answer: data.answer || "",
        hint: data.hint || "",
        explain: data.explain || "",
        questionType : data.questionType ||"",
        url: data.url 
      });

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [media, setMedia] = useState<any>()
    const [url, setURL] = useState(formData.url)

    useEffect(() => {
        // Ajuster dynamiquement la hauteur du textarea en fonction de son contenu lors du chargement initial
        adjustTextAreaHeight();
    }, []);

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
        adjustTextAreaHeight();
        
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const {success,message} = await uploadFile(media)
        // console.log("l'url a été modifié",formData)
        var updatedQuestion = {...data, ...formData}
        const { successUpdate, messageUpdate} = await updateQuestionFromSupabase(updatedQuestion);
        if (success&&successUpdate) {
            toast!.open("La question a été ajoutée avec succès!", "success")
        } else {
            toast!.open(`Échec de la mise à jour : ${messageUpdate}`, "error")
            toast!.open(`Échec de pour la mise en place du fichier : ${message}`, "error")
        }
        setModalIsOpen(true);
    };

    const adjustTextAreaHeight = () => {
        const textAreas = document.querySelectorAll('textarea');
        textAreas.forEach((textarea) => {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        });
    };
   
    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div>
            <form onSubmit={handleSubmit} className="form-inside">
            {/* itierate through formData for all textField */}
            <div className= "form-head">
                    <p className='form-title'> Editer la question </p>   
                </div>
                <button onClick = {()=>props.setTrigger(false)}className="popup-btn">X</button> 
            {/* Affiche la réponse serveur */}
            
                    <div className='form-header'>
                        <div>Question ID: {data.id}</div>
                        <div>Crée le: {new Date(data.create_at).toLocaleDateString('fr-FR')}</div>
                    </div>
                    {Object.entries(formData).map(([key, value]) => (
                        key!=="url"?<div key={key} 
                            className = "container-input">
                            <label className ="label-content"htmlFor={key}>
                                {key}
                            </label>
                            <textarea
                                id={key}
                                className = {`input-text`}
                                name={key}
                                value={value}
                                onChange={handleChange}
                                required = {key === 'content' || key === 'answer'?true:false}
                            />
                            {/* Il faut rajouter les catégories avec un des choses déjà préselectionnéer. Il faut isoler ce modulre. Possibilité d'intégrer directement avec des liste et puis appuyer sur croix pour éffacer. Quand click alors on peut enlever. Permet de renvoyer cette donnée au server */}
                            
                            {/* To do add:
                                    - form to modifie categorie
                                    - questionType dropdown 
                                    - Dropdown pour la réponse (trop difficile)
                                    - */}
                        </div>
                        :""
                        ))}
                    <UploadImage url = {url} setMedia ={setMedia} setURL = {setURL} formData = {formData}/>

            <div className = "popup-bottom">
                <GenericBtn bgColor = "red" onDelete = {()=>{props.onDelete(data.id)}}> Supprimer la question</GenericBtn>
                <button type="submit">Editer la question</button> 
            </div>
        </form>
    </div>
    );
}