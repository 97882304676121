import { Card } from "../../../components/index";

export default function AnswerCardManager({props}: {props:any}){ //display all possibility of answers
      

    const cardsData = [
      { content: props.answer_A, answerIndex: "A"},
      { content: props.answer_B, answerIndex: "B"},
      { content: props.answer_C, answerIndex: "C"},
      { content: props.answer_D, answerIndex: "D"},
      { content: props.answer_E, answerIndex: "E"},
    ];

    return(
      <div> 
          {cardsData
            .map((card, index) => (
              <div key={index}>
                {
                  // Verifie si le contenu existe
                card.content?
                <Card
                  key={card.answerIndex}
                  setAnswer={props.setAnsweredState}
                  answerState={props.isRightAnswered}
                  content={card.content}
                  answerIndex={card.answerIndex}
                  rightAnswer={props.answer}
                />
                : 
                ""
                }
              </div>
            ))}
      </div>
    )
}

