/** @jsxRuntime classic */
/** @jsx jsx */
import {jsx,css} from "@emotion/react"
import  { ReactNode } from 'react';
import mainTheme from "../shared/theme";
import {  AiOutlineCheckCircle, AiOutlineCloseCircle} from "react-icons/ai";
import { useState, useEffect } from 'react';

//
// CONTAIN ALL CARDS COMPONANT FOR THE APP
//


interface InfoCardProps {
    setHidden: (value: boolean) => void;
    children: ReactNode;
    height?: string;
  }
  
export function InfoCard({ setHidden, children, height}: InfoCardProps) {
  
    return (
      <div
        className="info_card"
        onClick={() => setHidden(false)}
        css={css`
          height: ${children? "auto" : "0"};
          display: flex;
          flex-direction: column;
          font-size: ${mainTheme.fontSizes.small};
          padding-top: 10px;
          justify-content: center;
          align-items: center;
          text-align: center;
          background: ${mainTheme.color.secondColor};
          border-radius :10px;
          margin: 20px;
          padding-bottom:15px;
          width: ${children? "60vh" : "1"}};
          transition: width 0.3s ease-out;
        `}>
        {children}
      </div>
    );
  }

 

//Constructor cards
interface CardProps {
    answerIndex : string;
    setAnswer: Function; 
    answerState: string;
    content: string;
    rightAnswer: string;
  }

export function Card({ answerIndex, setAnswer, content, answerState,rightAnswer}: CardProps) {
  const isRight = () => {
    return answerIndex === rightAnswer;
  }

  useEffect(() => {
    setClick(false);
    setAnswer("notAnswer") // Réinitialisez isClicked à false
  }, [content]);

   const [isClicked, setClick]= useState(false);
   const cardStyles = css({
          display: 'flex', // Utiliser Flexbox
          flexDirection: 'row', // Afficher le texte et les icônes en colonne
          alignItems: 'center',
          padding: 20,
          margin: 10,
          borderRadius: 10,
          minWidth: "30vw",
          alignContent: "center",
          position: isClicked? "relative":"static",
          left: isClicked? isRight()?"100px":"-100px": "0px",
          // Exemple de positionnement relatif
          background: isClicked 
                ? (isRight() ? mainTheme.color.rightAnswerColor : mainTheme.color.errorColor)
                : mainTheme.color.bgColor,
          fontWeight: 'bold',
          transition: 'background-color 0.3s ease, left 0.5s ease,  right 0.5s ease ',
          maxWidth: "40vw"
    });

    const onClick = () => {
      if (answerState !== 'isRight' && !isClicked) {
            setClick(true);
      
        if (isRight()) {
          setAnswer('isRight');
        } else if(answerState !== "secondMistake") {
          setAnswer('firstMistake');
        }
        if (answerState ==="firstMistake" && !isRight()){
            setAnswer("secondMistake")
        }
      }
    };
    return (    
      <div css={cardStyles} onClick={onClick}>
     
            {isClicked && !isRight() && <AiOutlineCloseCircle size={25}/> }
            {answerIndex} { content.charAt(0).toUpperCase() + content.slice(1)}
            {isClicked && isRight() && <AiOutlineCheckCircle size={25}/> } 
      </div>
    );
  }


type DifficultyLevel = 1 | 2 | 3;
export function ChallengeCard({number}:{number:DifficultyLevel}){
      const level = {
            1:"Facile",
            2:"Moyen",
            3:"Difficile",
      }
      return(
            <div css = {css`
            font-weight: 800`}>Difficulté : {level[number]}</div>
      )
}
  
