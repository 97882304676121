import { Routes, Route } from "react-router-dom";
import './App.css';
import {MCQComponants, Root, Homepage, RessourcePage, TopicNavigationPage,DebugPage, SigninPage, SigninAdminPage} from "./pages"
import TestPage from './pages/test';
import { ProtectedRoute, LogInRoute, ProtectedRouteAdmin} from './components';



function App() {
  return (
    <Routes>
      <Route element = {<Root/>}>
        <Route path="/" element={
                                  <ProtectedRoute>
                                      <Homepage />
                                  </ProtectedRoute>
                                  } />
        <Route path="/test" element={<TestPage />} />
        <Route path="/login" element={
                                  <LogInRoute>
                                    <SigninPage />
                                  </LogInRoute>
                                    } />
        <Route path="/adm" element={
                                   <LogInRoute>
                                    <SigninAdminPage />
                                   </LogInRoute>
                                 } />
        <Route path="/debug" element={
                            <ProtectedRouteAdmin>
                              <DebugPage />
                            </ProtectedRouteAdmin>
                            }/>
        {/* Need to remake protectedRoute to login */}
        <Route path="/QCM" element={ 
                  <ProtectedRoute>
                    <TopicNavigationPage/>
                  </ProtectedRoute>
                } />
        <Route path="/QCM/:id" element={
                  <ProtectedRoute>
                    <MCQComponants/>
                  </ProtectedRoute>
                } />
      </Route>
    </Routes>
  );
}
export default App;
