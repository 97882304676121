import React, {useState, useEffect} from 'react'
import "./componant.css"
import { insertError } from '../../../API/errorAPI';
//componants to send a error rapport. 
//sending to the database
export default function ErrorRaport(props:any) {
  const [comment,setComment] = useState("")
  const [errorLogData, setErrorLogData] = useState<errorLog>({
    comment: "",
    title: "",
    id_question:props.id
  });
  const [selectedTitle, setSelectedTitle] = useState("repetition");

   type errorLog = {
    title: string ,
    comment : string, 
    id_question: number
  }

  const data = props.data

  const handleSumit=()=>{
    props.setTrigger(false)
    insertError(errorLogData)
  }
  
  useEffect(() => { 
  }, [errorLogData,selectedTitle]);

  // Met à jour le commentaire dans errorLogData
  const handleTextChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setComment(newValue);
    setErrorLogData((prevData) => ({
      ...prevData,
      comment: newValue,
    }));
    
  };
// Ne semble pas mettre à jour lors des modifications
  const handleSelectedText = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedTitle(newValue);
    setErrorLogData((prevData) => ({
      ...prevData,
      title: newValue
    }));
  };

  return (
    <div className='error-container-form'>
      <button onClick={()=>props.setTrigger(false)}>Quitter</button>
      <h2>Rapport</h2>
      <form id ="error-rapport">
        
        <section className='error-content'>
        <div className='text'>Résumé de la question</div>
        <div className ="display-content">
            <div className='text'>Contenu de la question</div>
            <div>{data.content}</div>
          </div>
          {/* Display all answers */}
            {['A', 'B', 'C', 'D', 'E'].map((letter) => (
            data[`answer_${letter}`]?<div className="display-content" key={letter}>
              <div className="text">Réponse {letter}</div>
              <div>{data[`answer_${letter}`]}</div>
            </div>:""
          ))}
          <div className ="display-content">
            <div className='text'>La réponse</div>
            <div>{data.answer}</div>
          </div>
        </section>

        <section className='error-comment'>
        <label htmlFor=''>En tête du rapport</label>
          <select name="dropdown" id="dropdown-title" 
          onChange={handleSelectedText} value={selectedTitle}>
            <option value="repetition">Il s'agit d'une répétition d'une autre question</option> 
            <option value="contentError">Erreur dans la donnée de la question</option> 
            <option value="answerInsideError">Erreur dans une des réponses</option> 
            <option value="answerError">Erreur dans la réponse</option> 
            <option value="other">Autre</option> 
          </select>
          <label htmlFor='errorComment'>Vos commentaires</label>
          <textarea placeholder= "Entrez votre text" value = {comment} id="errorComment" 
          onChange={handleTextChange} 
          name="errorComment" 
          required></textarea>
          <button id ="submit-btn"  onClick={handleSumit} >Envoyé le rapport</button>
        </section>
      </form>
    </div>
  )
}
