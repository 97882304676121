
/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import { jsx, css} from '@emotion/react'
import {Header, Footer} from '../components'
import { Outlet } from "react-router-dom";
export default function Root() {
  return (
    <div css={css`
    display:flex;
    flex-direction: column;
    min-height: 100vh;
      `}>
      <Header />
        <Outlet/>
      <Footer/>
    </div>
  )
}



