import { supabaseClient } from "../config/supabase-client"
import { useState,useEffect } from "react";


const TABLE : string = "questions"
//fetching question
export function useFetchQuestionFromSupabase() {
    const [questions, setQuestions] = useState<any[] | []>([]);
    
    useEffect(() => {
        fetchQuestions();
    }, []);
    async function fetchQuestions() {
        try {
            let { data: questionsData, error } = await supabaseClient
                .from(TABLE)
                .select('*');
            if (error) {
                throw new Error(error.message);
            }
            setQuestions(questionsData || []);
        } catch (error) {
            console.error("Erreur lors de la récupération des données :", error);
        }
    }
    return questions;
}
//update question
export async function updateQuestionFromSupabase(update:any ){
    try{
        const { data, error } = await supabaseClient
                .from(TABLE)
                .update({ 
                    "content": update.content,
                    "answer_A": update.answer_A,
                    "answer_B": update.answer_B,
                    "answer_C": update.answer_C,
                    "answer_D": update.answer_D,
                    "answer_E": update.answer_E,
                    "answer": update.answer,
                    "hint": update.hint,
                    "explain": update.explain,
                    "url": update.url
                })
                .eq("id", update.id)
                .select()
        console.debug("Edit with success")
        if (error){
            console.error("Erreur lors de la mise à jours des données", error)
            return {successUpdate:false, messageUpdate: `Erreur lors de la mise à jours des données ${error}`}
        } else{
            return {successUpdate:true, messageUpdate:"Base de donnée mise à jour avec succès!"}
        }
    } catch(error){
        console.error("Erreur lors de la récupération des données :", error);
        return {successUpdate:false, messageUpdate:"Errur lors de la récupération des données:", error}
    }
}
//delete question
export async function deleteQuestionDatabase(id:number ){
    try{
        console.log("Début de la suppression")
        const { error } = await supabaseClient
            .from(TABLE)
            .delete()
            .eq('id', id)
        console.debug("delete with success")
        if (error){
            console.error("Erreur lors de la mise à jours des données", error)
            return {success:false, message: `Erreur lors de la supression des données ${error}`}
        } else{
            return {success:true, message:`Question ${id} supprimée avec succés`}
        }
    } catch(error){
        console.log(error)
        return {success:false, message:"Erreur lors de la récupération des données:", error}
    }
}

export async function addingQuestionDatabase(datas:any){
    try{
        console.log("Début d'ajout des questions")
        console.log("Donnée envoyée", )
        console.log("Donnée",datas.content)
        console.log("Donnée", datas.id)
        const { error } = await supabaseClient
                .from(TABLE)
                .insert([
                    datas,
                ])
                .select()
        if (error){
            console.error("Erreur lors de l'ajout des données", error.message)
            return {success:false, message: `Erreur lors de l'ajout des données ${error.message}`}
        } else{
            return {success:true, message:"Base de donnée supprimée avec succès!"}
        }

    } catch(error){
        return {success:false, message:`Erreur lors de la connexion avec le serveur! ${error}`}
    }
}

export function questionSubscription(){
    const questionTest = supabaseClient.channel('custom-all-channel')
        .on(
            'postgres_changes',
            { 
                event: '*', 
                schema: 'public', 
                table: TABLE },
            (payload) => {
            console.log('Change received!', payload)
            }
        )
        .subscribe()
  return questionTest
}

export async function uploadFile(media:any){
    try{
      const {data,error} = await supabaseClient.storage.from("question_media")
      .upload(`MCQ/${media.name}`,media,{
        upsert: true,
      })
      if (error){
        console.log("Il y a eu une erreur:", error.message)
        return {success: false, message : error.message}
      } else{
        console.log("Le fichier a bien été téléversé")
        return {success: true, message : "Le message a bien été téléversé"}
      }
    } catch (err){
      console.log("Erreur pour téléverser le fichier:", err)
      return {success: true, message : "Erreur pour téléverser le fichier:", err}
    }
  }

  export function getURL(url:string){
    return supabaseClient.storage.from('question_media').getPublicUrl(url)
  }
