/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react'
import {jsx, css} from "@emotion/react"
import mainTheme from '../../shared/theme'
export default function Homepage() {
  return (
    <article css = {css`
        display:flex; 
        flex-direction: column;
        font-size:${mainTheme.fontSizes.small};
        align-items:center;`}>
        <h2>Bienvenu sur SauveMesFeds!</h2>
        <div  css = {css`
        justify-content:center; 
        font-size:1.5em;
        padding:10vh;
        `} className="container">


        <div> Coucou les ami-e-s! Ce petit site repértorie quelques les ressources nécessaires pour passer au mieux les fédéraux! 
          Je vous prie de pas trop le partager car j'aimerai pouvoir bien continuer ma carrière en médecine sans avoir déjà Berne sur mon dos </div> 
        <ul> 

            <li>Sur "QCM" avec quelques questions provenant des feds. Je vais essayer de le mettre à jour. JE VOUS PRIS S'IL VOUS PLAIT de ne pas transmettre le mot de passe car je pourrais vraiment avoir des problèmes. Sinon je devrais changer :(</li>
            <li>Attention! Il peut contenir des erreurs! Merci de le signaler, je ferai au mieux pour modifier (j'espère que le log erreur marche)</li>
            <li>Bonne chance à tous! Pleins de courage</li>

        </ul>
          
     </div>
    </article>
  )
}
