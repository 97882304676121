import React, {useState} from 'react'
import { useAuth } from '../../hook/authProvider'
import "./signin.css"
import {  useNavigate } from "react-router-dom";
import { useToast } from '../../hook/toasterProvider';
//todo:
//- CSS and styling
//- Loading page or animation
//- Returning to homepage 

export default function SigninAdminPage() {
    const toast = useToast()
    const [userName, setUser] = useState("admin@sauvmesfed.ch")
    const [passwordUser, setPasswordUser] = useState("")
    const [isLoading, setLoading] = useState(false)
    const { signIn, user, signInMagical } = useAuth()
    const navigate = useNavigate()

    async function handleSubmitGuestAccount (){
      const dataLogin = {
        email: userName,
        password : passwordUser
      }
      //A modifier pour faire avec réponse server
        setLoading(true) 
        const {message, errorLogin} = await signIn(dataLogin)
        if (errorLogin){
          toast!.open(`${message}`, "error")
          setLoading(false)
        } else {
          toast!.open(`${message}`, "success")
          setLoading(false)
          navigate("/")
        }

        //FAIRE ATTENTION RENVOYER ERRUR SI MARCHE PAS! 
    }



    return (
    <div>
        <div>
          <div className='signIn-container'> 
            <div className='login-container'>
              <div className='label'>
                <label htmlFor="username">Email:</label>
                <input 
                  type="text" 
                  id="username" 
                  name="username" 
                  placeholder="Votre email" 
                  onChange={(e)=>setUser(e.target.value)} 
                  value = {userName}
                  />
                <label htmlFor="password">Mot de passe:</label>
                <input type="password" id="password" name="password" placeholder="Insérez votre mot de passe" onChange={(e)=>setPasswordUser(e.target.value)} value = {passwordUser}/>
                  <button className = "btn-sending" onClick ={handleSubmitGuestAccount}>Se connecter</button>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
