import React from 'react'

import { Navigate } from "react-router-dom";
import { useAuth } from '../hook/authProvider';

export const ProtectedRouteUser = ({ children }:any) => {
  const { user } = useAuth();
  if (!user) {
    return <Navigate to="/login" />;
  }
  return children;
};

export const ProtectedRouteAdmin = ({ children }:any) => {
  const { admin } = useAuth();
  if (!admin) {
    return <Navigate to="/" />;
  }
  return children;
};


export const LogInRoute = ({ children }:any) => {
  const { user } = useAuth();
  if (user) {
    return <Navigate to="/" />;
  }
  return children;
};