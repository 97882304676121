/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css} from '@emotion/react'
import DataSpecialisation from "../../shared/data/speciality.json";
import mainTheme from '../../shared/theme';
import { Link } from 'react-router-dom';
import { useFetchQuestionFromSupabase } from '../../API/questionAPI';


export default function TopicNavigationPage() {
  //A list containg all speciality with questions
  const datas = useFetchQuestionFromSupabase()
  const listSpeciality = getAllSpecialitiesAndQuestions(datas)

  function getTotalQuestion(id:string) {
    var lengthQuestion = datas.filter((data)=>(data.categories.split(",").includes(id))).length
    return lengthQuestion
  }
  const gridContainerStyles = css`
        display: grid;
        gap: 20px;
        padding-right: 20vh;
        padding-left: 20vh;
        padding-bottom:10vh;
        @media (min-width: 900px)  {
          grid-template-columns: repeat(3, 1fr);
        };
        @media (min-width: 500px) and (max-width: 900px) {
          grid-template-columns: repeat(2, 1fr);
        };
        @media (min-width: 300px) and (max-width : 500px) {
          grid-template-columns: 1fr;
        };
        a{
          text-decoration:none;
          color:black;
  }`;
const gridHoverStyle = css`
  &:hover {
    .square {
      background: ${mainTheme.color.darkenBgColor};
      transform: scale(1.05);
    }
  }
`;
  return (
    /* A rajouter:
    - Nombre de questions présents
    - Question test
    */ 
    <div>
    <h1 css={css`text-align: center`}>Choisissez votre spécialisation</h1>
   
    <div css={gridContainerStyles}>
      <Link to ={`/QCM/`}>  
          <div key= {0} css={gridHoverStyle}>
            <Card name = "EXAMEN" description="Prêt pour ne rien savoir?"/>
          </div>
      </Link>
      
      {/* display speciliaty with questinos */}
      {DataSpecialisation.map((specialization, index) => {
        return listSpeciality.includes(specialization.idSpe) && //display only when there're questions, maybe doing list than card? 
          <Link to ={`/QCM/${specialization.idSpe}`}>  
              <div key={index} css={gridHoverStyle}>
                <Card name = {specialization.name} description={specialization.description} total = {getTotalQuestion(specialization.idSpe)}/>
              </div>
          </Link>
      })}
    </div>
  </div>
  );
}

//Display on a list, better to read! 
function Card(props:any) {
  const squareStyle = css`
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    background: ${mainTheme.color.bgColor};
    text-align: center;
    transition: transform 0.3s, background 0.3s;`

  return (
    <div className = "card-navigation">
      <div css={squareStyle} className="square">
        <h2>{props.name}</h2>
        <p>{props.description}</p>
        <p>Nombre de questions: {props.total?props.total:"0"}</p>
      </div>
    </div>
  );
}

function getAllSpecialitiesAndQuestions(datas: any[]): string[] { 
  //create a list speciality with questions
  //never put any hook in a function cause can't render! Must passing data through argument
  
  const listCategory: string[] = []
  const questionBySpe = {}

  datas.forEach(data => {
    for (let i = 0; i < data.categories.length; i++) {
        const arraySpec = data.categories.split(",")
        var currentCategorie = arraySpec[i]
        if (!listCategory.includes(currentCategorie)){
          listCategory.push(currentCategorie);
        }
    }
  });

  return listCategory
}