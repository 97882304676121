import {useState} from "react"
import {InfoCard } from "../../../components";

export default function FeedBackManager({props}:{props:any}){ // give a feed back at the end of the question
    const [isHidden, setHidden] = useState(true)

    const HintToogle = <div> {isHidden?  <h2>Besoin d'aide? Appuyé ici</h2>:`${props.hint}`}</div>
    const {hint,answerExplain,sources} = props

    
    let infoCardContent;
    switch (props.answeredState) {
          case "isRight":
            infoCardContent = 
            <div> 
                 <h1>Bonne réponse!</h1>
                <div >{answerExplain}</div>
                <div>{sources}</div>
            </div>
           
            break;
          case "firstMistake":
            infoCardContent = 
            <div > 
                <h1> Mauvaise réponse</h1>
                      {HintToogle}
                </div>  
                break;
          case "secondMistake": 
                infoCardContent = 

                <div> 
                      <h1> Mauvaise réponse</h1>
                      <div>{hint}</div>
                </div>  
               
                
                break;
          default:
            infoCardContent = "";
        }
    
        return (
          <InfoCard setHidden={setHidden} >
            {infoCardContent}
            
          </InfoCard>
        )
}
