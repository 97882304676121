const mainTheme = {
    color : 
        { mainColor: "#1E152A",
        secondColor: "#D1CEE5",
        rightAnswerColor: "#A5C882",
        errorColor: "#EF5B3A",
        bgColor: "#C6E2E9",
        darkenBgColor: "#A7BED3"},
    fontSizes : {
        small: "1em",
        medium: "2em",
        large: "3em",
        headTitle : "4em",
        },
    fontWeigt:{
        normal: 400,
        semiBold: 600,
        bold: 700,
        extraBold:800,
        black: 900
    },
  };

  
  export default mainTheme