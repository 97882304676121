import {useState} from "react"
import { ButtonArrow, IsFav, } from "../../../components";
import {jsx,css} from "@emotion/react"
import {  AiOutlineArrowRight,AiOutlineArrowLeft} from "react-icons/ai"; 

/** @jsxRuntime classic */
/** @jsx jsx */
export default function MCQnavigator({props}: {props:any}){ //componant who naviguate between questions
    const [isFav, setFav]= useState(false);
    const {setQuestionIndex, questionIndex, dataLength} = props
    const updateQuestionIndex = (newIndex: number) => {
          if (newIndex >= 0 && newIndex < dataLength) {
            setQuestionIndex(newIndex);
          }
        };
    return(
          <div  css = {css`
          display:flex;
          flex-direction: row;
          align-items: center`}>
          <ButtonArrow props= {{icon: <AiOutlineArrowLeft/>,
                               setQuestionIndex: ()=>updateQuestionIndex(questionIndex-1)}}/>
                <p css={css`
                      padding: 32px;
                      font-size: 20px;
                      font-weight: 700`}>
                Question {questionIndex+1}/{dataLength} 
                </p> 
                <IsFav 
                      setFav = {setFav}
                      isFav = {isFav}
                /> 
          <ButtonArrow props= {{icon: <AiOutlineArrowRight/>,
                               setQuestionIndex: ()=>updateQuestionIndex(questionIndex+1)}}/>


    </div>)
}